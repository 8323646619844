import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Manager',
        component: () => import('../views/Manager.vue'),
        redirect: '/home',  // 重定向到主页
        children: [
            {path: '403', name: 'Auth', meta: {name: '无权限'}, component: () => import('../views/manager/Auth')},
            {path: 'home', name: 'Home', meta: {name: '系统首页'}, component: () => import('../views/manager/Home')},
            {path: 'user', name: 'User', meta: {name: '用户信息'}, component: () => import('../views/manager/User')},
            {
                path: 'person',
                name: 'Person',
                meta: {name: '个人信息'},
                component: () => import('../views/manager/Person')
            },
            {
                path: 'password',
                name: 'Password',
                meta: {name: '修改密码'},
                component: () => import('../views/LoginAndRegister/Password.vue')
            },
            {
                path: 'firstSwiperDot',
                name: 'FirstSwiperDot',
                meta: {name: '首页轮播图'},
                component: () => import('../views/swiperDot/FirstSwiperDot.vue')
            },
            {
                path: 'advSwiperDot',
                name: 'AdvSwiperDot',
                meta: {name: '广告位轮播图'},
                component: () => import('../views/swiperDot/AdvSwiperDot.vue')
            },
            {
                path: 'prayRule',
                name: 'prayRule',
                meta: {name: '祈愿规则'},
                component: () => import('../views/prayRule/prayRule.vue')
            },
            {
                path: 'prayType',
                name: 'prayType',
                meta: {name: '祈愿类型'},
                component: () => import('../views/prayType/prayType.vue')
            },
            {
                path: 'YsPrayHistory',
                name: 'YsPrayHistory',
                meta: {name: '官方祈愿记录'},
                component: () => import('../views/prayHistory/YsPrayHistory.vue')
            },
            {
                path: 'goods',
                name: 'goods',
                meta: {name: '原神物品'},
                component: () => import('../views/goods/goods.vue')
            },
            {
                path: 'SimulatePrayHistory',
                name: 'SimulatePrayHistory',
                meta: {name: '模拟祈愿记录'},
                component: () => import('../views/prayHistory/SimulatePrayHistory.vue')
            },
            {
                path: 'PlayerCard',
                name: 'PlayerCard',
                meta: {name: '角色卡片'},
                component: () => import('../views/PlayerJudge/PlayerCard.vue')
            },
            {
                path: 'ZDL',
                name: 'ZDL',
                meta: {name: '深渊信息'},
                component: () => import('../views/PlayerJudge/ZDL.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        meta: {name: '登录'},
        component: () => import('../views/LoginAndRegister/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        meta: {name: '注册'},
        component: () => import('../views/LoginAndRegister/Register.vue')
    },
    {path: '*', name: '404', meta: {name: '无法访问'}, component: () => import('../views/404.vue')},
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // to 是到达的路由信息
    // from 是开源的路由信息
    // next 是帮助我们跳转路由的函数
    let adminPaths = ['/user']
    let user = localStorage.getItem('userInfo') || '{}'

    if (user.role != undefined && user.role !== 'manager' && adminPaths.includes(to.path)) {
        // 如果当前登录的用户不是管理员，然后当前的到达的路径是管理员才有权限访问的路径，那这个时候我就让用户去到一个没有权限的页面，不让他访问实际的页面
        next('/403')
    } else {
        next()
    }
})

export default router
